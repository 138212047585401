@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
body {
	background-color: #141414;
}

#bg > div {
	background-position: right;
}

.swiper-pagination-bullet {
	width: 100px !important;
	opacity: 0.8 !important;
	background-color: gray !important;
	border-radius: 0.5rem !important;
	cursor: pointer;
	height: 5px !important;
}

.swiper-pagination-bullet-active {
	background-color: white !important;
}
